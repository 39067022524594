const HOST_NAME_TEST = 'test.idas-ibaraki.jp';

const GAZOU_PATH = "http://test.idas-ibaraki.jp:80/genba/";

const SCREEN_NAME = {
    'M1100': 'ログイン認証',
    'M1101': 'メインメニュー',
    'M1102': 'パスワード変更',
    'M1200': '災害報告管理メニュー',
    'M1201': '国庫負担申請管理メニュー',
    'M1202': '災害査定管理メニュー',
    'M1203': '本庁用処理メニュー',
    'FA100': '災害名称 登録/更新/削除',
    'FA101': '被害報告一覧',
    'FA102': '被害報告入力 登録/更新/削除',
    'FA104': '気象情報一覧',
    'FA107': '被害報告書　施設災害１ 登録/更新/削除',
    'FA109': '被害報告書　施設災害２ 登録 / 更新 / 削除',
    'FA103': '災害報告集計一覧',
    'FA105': '一般被害等 登録/更新/削除',
    'FA110': '被害報告（道路通行止・登録/更新/削除）',
    'FA112': '気象情報入力 登録/更新/削除',
    'FA111': '災害原因　登録/修正/削除',
    'FB100': '国庫負担申請一覧',
    'FB101': '国庫負担申請 登録/更新/削除',
    'FB102': '国庫負担申請　工事概要　登録/更新/削除',
    'FB103': '国庫負担申請　経済効果　登録/更新/削除',
    'FB104': '改良復旧（登録/更新/削除）',
    'FB105': '国庫負担申請　内未内転　登録/更新/削除',
    'FB106': '工事番号振分',
    'FB108': '国庫負担申請管理・帳票出力',
    'FC100': '災害査定一覧',
    'FC101': '災害査定 登録/更新/削除',
    'FC104': '災害査定　内未内転　登録/更新/削除',
    'FC105': '災害査定管理・帳票出力',
    'FA113': '被害報告書　工種別金額　登録',
    'FA114': '災害報告管理・帳票出力',
    'FC102': '災害査定　工事概要　登録/更新/削除',
    'INF002': 'お知らせ編集',
    'FC103': '改良復旧（登録/更新/削除）',
    'FG203': '本庁処理　様式ダウンロードメニュー ',
    'FG202': '本庁処理.帳票出力',
    'FG204': '本庁処理　様式アップロード',
    'FD101': 'ログインユーザ 一覧',
    'FD102': 'ログインユーザ 登録/更新/削除',
    'AUTHERROR': '災害管理集計システム　権限エラー'
};
const KENGEN_CD = {
    'ADMIN': '1',
    'KEN': '2',
    'SHICYOUSON': '3',
    'YOSHIKI': '4',
    'GENBA': '5',
    'GENBATOUROKU': '9',
};
const LOGIN_CD = {
    'ADMIN': 'i',
};
const SHICYOU_KBN = {
    'KEN': '4',
    'DAM': '3',
    'SHICYOUSON': '2',
    'DOBOKU': '1',
};
const JIGYOU_CD = {
    JIGYOU_CD1: '1',
    JIGYOU_CD2: '2',
};
const FA101 = {
    'NEW_KBN': '1',
    'KANRISYA_ALL': '0'
};
const FA102 = {
    'DEFAULT_YOSAN_KBN': '1',
    'DEFAULT_SHOKAN': '11',
    'HISAIJYOU_CD_OTHER': '99',
    'OUKYUKOJI_HOUHOU_OTHER': '99',
    'KOSYU_KASEN': '1',
    'KOSYU_KYORYO': '7',
    'JIGYOU_SHI': '2',
};
const FA103 = {
    'KEN': '県',
    'KENKOUJI': '県工事',
    'SHICHOUSON': '市町村',
    'SHICHOUSON_KOUJI': '市町村工事',
};
const FA105_JINTEKI_NAMES = [
    '死者',
    '行方不明',
    '負傷者',
    '避難者',
    '避難勧告'
];
const FA105_JYUKA_NAMES = [
    '全壊',
    '半壊',
    '流失',
    '床下浸水'
];
const FC101 = {
    '1': '本省査定',
    '2': '整備局査定',
    'OTHER': '査定区分不明',
};
const FB100CSVFN = [
    '国庫負担申請データ'
];
const FB101 = {
    'DEFAULT_SYOKAN_CD': '11',
    'HISAIJYOU_CD_OTHER': '99',
};
const FA114EXCELFN = {
    'LA203': '災害報告管理_'
};
const FB108EXCELFN = {
    'FB108': '国庫負担申請管理'
};
const FAC05EXCELFN = {
    'LC400': '災害査定管理'
};
const FB106 = {
    'KARITOROKU': '1',
    'HONTOROKU': '2',
};

const RECAPTCHA_SITE_KEY = "6LdVAQcqAAAAAGxgA0XxM0OrbCg1x_pbRxA1Wh5P";

export default {
    HOST_NAME_TEST,
    GAZOU_PATH,
    SCREEN_NAME,
    KENGEN_CD,
    SHICYOU_KBN,
    LOGIN_CD,
    JIGYOU_CD,
    FA101,
    FA102,
    FA103,
    FA105_JINTEKI_NAMES,
    FA105_JYUKA_NAMES,
    FC101,
    FB100CSVFN,
    FB101,
    FA114EXCELFN,
    FAC05EXCELFN,
    FB106,
    FB108EXCELFN,
    RECAPTCHA_SITE_KEY,
}
