import axios from 'axios';
import router from './router.js'

axios.defaults.baseURL = 'http://test.idas-ibaraki.jp:80/api';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('AUTH_TOKEN');

axios.interceptors.response.use((response) => {
    return response
}, function(error) {
    let originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
        router.push({
            name: 'M1100'
        })
        localStorage.clear();
    }
    return Promise.reject(error)
})

export class APIService {

    constructor() { }

    loginUser(request_data) {
        return new Promise((resolve, reject) => {
            axios.post('/login', request_data)
                .then(response => {
                    this.setRemember(request_data);
                    resolve(response.data);
                })
                .catch(error => {
                    localStorage.clear();
                    reject(error.response.data);
                })
        });
    }

    overwriteLogin(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/overwriteLogin', request_data)
                .then(response => {
                    this.setRemember(request_data);
                    resolve(response.data);
                })
                .catch(error => {
                    localStorage.clear();
                    reject(error.response.data);
                })
        });
    }

    logoutUser() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/logout')
                .then(response => {
                    localStorage.setItem('AUTH_TOKEN', '');
                    resolve(response.data);
                })
                .catch(error => {
                    localStorage.clear();
                    console.log('in Logout function [API SERVICE]', error.response);
                    reject(error);
                })
        });
    }
    getSaigaiMeisyou(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/getmeisyou', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    getGengo(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/gengo', request_data)
                .then(response => {
                    var year = parseInt(request_data.date.toString().substring(0, 4));
                    var gengoData = response.data;
                    var start = parseInt(gengoData[0].start_date.toString().substring(0, 4));
                    var jpYear = gengoData[0].gengo_cd + (year - start + 1);
                    response.data = jpYear;
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    getGengoStartDate(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/gengo/startDate', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    getShcyousonLit() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/shcyousonlist')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    getGeninList() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/geninlist')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    checkMcode(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/mcodecheck', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    getKanrisya() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/getKanrisya')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    getKanrisyaByFlg(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/getKanrisyaByFlg', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    getRegistrantByFlg(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/getRegistrantByFlg', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa101GetMaxSaigaiKen(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa100/getMaxSaigaiKen', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa101GetSaigaiMeisyou(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa101/getmeisyou', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fa101Search(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa101/search?page=', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa103searchData(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa103/search', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa103aggregateData(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa103/aggregatedata', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response);
                });
        });
    }

    fa103getHoukokuDataCount(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa103/houkokuCount', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa105getJintekiList(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa105/getjinteki', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fa105getJyukaList(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa105/getjyuka', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa105UpdateData(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa105/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }


    fa107getHigaiSisetu1Data(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa107/getsisetu1', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fa107getCheckList() {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa107/getchecklist')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa107getShcyousonCheckListKbn(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa107/shcyousoncheckkbn2', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa107updateData(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa107/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa107checkRokasenCd(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa107/checkrokasenCd', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa100Search(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa100/search', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fa100Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa100/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error.response.data);
                    reject(error.response.data);
                });
        });
    }
    fa100Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa100/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fa100Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa100/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa102Detail(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/getdetail', request_data)
                .then(response => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa102GetShityousonList(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/getShityousonList', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa102GetCyouazamei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/getCyouazamei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa102GetHisaibi(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/getHisaibi', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa102GetSeiri(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/getSeiri', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa102GetKasenshubetsumei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/getKasenshubetsumei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa102GetRokasenmei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/getRokasenmei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa102GetHisaijoumei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/getHisaijoumei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa102GetOukyukojiHouhou(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/getOukyukojiHouhou', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa102getItijoho(formData) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/getItijoho', formData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa102Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa102Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa102UpdateFile(formData) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/updateFile', formData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa102Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa102/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa104GetHigaiMeisyou(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa104', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa104Search(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa104/search', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa109getHigaiSisetu2(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa109/getHigaiSisetu2', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa109insertHigaiSisetu2(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa109/insertHigaiSisetu2', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
    fa109shcyousonCheckKbn2(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa109/shcyousoncheckkbn2', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa109getChecklist(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa109/checklist', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa109getKasenshubetsumei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa109/getKasenshubetsumei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa109checkKasenshubetu(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa109/checkKasenshubetu', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }


    fa110GetMeisyou(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa110', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa110getKanrisya(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa110/getKanrisya', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa110Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            console.log("Api service FA110Insert")
            axios.post('/fa110/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa110Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa110/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa111getRenban(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa111/getRenban', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
    fa111getRenbanData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa111/getRenbanData', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa111getShicyouMei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa111/getShicyouMei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa111Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa111/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa111Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa111/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa111Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa111/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa112GetMeisyou(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa112', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa112Detail(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa112/getdetail', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa112GetShicyouson() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa112/getShicyouson')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa112GetHoukokusyacd(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa112/getHoukokusyacd', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa112Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa112/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa112Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa112/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa112Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa112/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fa113GetMeisyou(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa113', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fa113getKousyuGakuData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa113/getKousyuGakuData', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa113Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa113/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa114Search(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa114/search', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa114getSaigaiHoukokuDataCheck(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fa114/fa114getSaigaiHoukokuDataCheck', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fb100getKotukoSinseiData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb100/fb100getKotukoSinseiData', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fb100getMcodeList() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb100/getMcodeList')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb100update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb100/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fb100exportCSV(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb100/export', request_data, { responseType: 'blob' })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fb103getKeizaikoukaData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb103/fb103getKeizaikoukaData', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fb103Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb103/fb103Insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fb103Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb103/fb103Update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fb103Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb103/fb103Delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    getKotukoSinsei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/getKotukoSinsei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb104getSaigaiKasenData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb104/getsaigaikasen', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fb104insertData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb104/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb104updateData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb104/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fb104deleteData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb104/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb105getKotukoNaiminaiten(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb105/getKotukoNaiminaiten', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fb105insertKotukoNaiminaiten(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb105/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb105updateKotukoNaiminaiten(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb105/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb105Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb105/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb108getMcodeList1(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb108/getMcodeList1', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb108getMcodeList2(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb108/getMcodeList2', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc100getSaigaiSateiData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc100/getSaigaiSateiData', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fc101Listbox() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc101/getListboxData')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc101KotukoShinseiData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc101/getKotuko', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc101getSaigaiSatei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc101/getSaigaiSatei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc101Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc101/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc101Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc101/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc101Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc101/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc102getKoujigaiyou(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc102/getKoujigaiyou', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc102Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc102/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc102Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc102/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc102Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc102/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc103getSaigaiKasenData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc103/getsaigaikasen', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fc103getKotukoKasenData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc103/getkotukokasen', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fc103getSateiData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc103/getsatei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fc103getMasterCheckLists() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc103/getmcodelist')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc103getMCode(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc103/getmcode', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fc103insertData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc103/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc103updateData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc103/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
    fc103deleteData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc103/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc104getAssessment(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc104/getAssessment', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc104getkotukoNaiminaiten(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc104/getkotukoNaiminaiten', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc104getsaigaiNaiminaiten(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc104/getsaigaiNaiminaiten', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    getMcodeList() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc104/getMcodeList')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc104insertSaigaiNaiminaiten(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc104/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc104updateSaigaiNaiminaiten(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc104/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc104Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc104/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc104getKanrisyamei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc104/getKanrisyamei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc105getMcodeList1() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc105/getMcodeList1')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc105getMcodeList2() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc105/getMcodeList2')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fc105Export(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fc105/export', request_data, { responseType: 'blob' })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    getOshirase() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/m1101/getOshirase')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    inf002getOshirase() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/inf002/getOshirase')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    inf002Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/inf002/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    inf002Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/inf002/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb102GetKotukoKojigaiyo(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb102/getKotukoKojigaiyou', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb102Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb102/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb102Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb102/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb102Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb102/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb101Detail(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb101/detail', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb101GetHisaijoumei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb101/getHisaijoumei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb101GetCyouazamei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb101/getCyouazamei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb101GetSyubetu(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb101/getSyubetu', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb101GetRokasen(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb101/getRokasen', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb101GetSeirinao(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb101/getSeirinao', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb101GetSaigaiHoukokuData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb101/getSaigaiHoukoukuData', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }


    fb101Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb101/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb101Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb101/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    getGengoName(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/gengo', request_data)
                .then(response => {
                    var year = parseInt(request_data.date.toString().substring(0, 4));
                    var gengoData = response.data;
                    var start = parseInt(gengoData[0].start_date.toString().substring(0, 4));
                    var jpYear = gengoData[0].gengo_name + (year - start + 1);
                    response.data = jpYear;
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fb106GetSaigaiMeisyou(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb106/getSaigaiMeisyou', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb106GetKoujifuriwake(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb106/getKoujifuriwake', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb106GetKotukoSinsei(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb106/getKotukoSinsei', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb106DeleteKojifuriwake(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb106/deleteKojifuiwake', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb106Koujino(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb106/getKoujino', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb106SaigaiHoukoku(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb106/getSaigaiHoukoku', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb106deleteAndInsertData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb106/deleteAndInsertData', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    fb106ChkFuriwakeData(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb106/chkFuriwakeData', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                })
        });
    }

    setLocalStorage(data) {
        (data.token) ? localStorage.setItem('AUTH_TOKEN', data.token) : '';
        if (data.user) {
            localStorage.setItem('SESSION_ID', data.user.session_id);
            localStorage.setItem('LOGIN_ID', data.user.login_id);
            localStorage.setItem('Kanrisya_Cd', data.user.kanrisya_cd);
            localStorage.setItem('LOGIN_NAME', data.user.login_name);
            localStorage.setItem('SHIMEI', data.user.shimei == null ? '' : data.user.shimei);
            localStorage.setItem('kengen_cd', data.user.kengen_cd);
            if (data.user.kengen_cd == "5" || data.user.kengen_cd == "9") {
                localStorage.setItem('Jimusyo_Mei', "");
                localStorage.setItem('shicyou_kbn', "");
                localStorage.setItem('Kanrisya_Mei', "");
            } else {
                localStorage.setItem('Jimusyo_Mei', data.kanrisya.jimusyo_mei);
                localStorage.setItem('shicyou_kbn', data.kanrisya.shicyou_kbn);
                localStorage.setItem('Kanrisya_Mei', data.kanrisya.kanrisya_mei);
            }
        }
    }

    setRemember(request_data) {
        if (request_data['rememberMe']) {
            localStorage.setItem('REMEMBER_LOGIN_ID', request_data['loginId']);
            localStorage.setItem('REMEMBER_PASSWORD', request_data['password']);
            localStorage.setItem('REMEMBER', true);
        } else {
            localStorage.setItem('REMEMBER_LOGIN_ID', '');
            localStorage.setItem('REMEMBER_PASSWORD', '');
            localStorage.setItem('REMEMBER', '');
        }
    }

    export(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/export', request_data, { responseType: 'blob' })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fb108Export(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fb108/export', request_data, { responseType: 'blob' })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fa103updateData(request_data) {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return new Promise((resolve, reject) => {
            axios.post('/fa103/updateData', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    koujigaiyouDownload() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/koujigaiyou/Download', null, { responseType: 'blob' })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    getYoushikiList() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fg203/getYoushikiList', { responseType: 'blob' })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    Download(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fg203/Download', request_data, { responseType: 'blob' })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    getFailinfo() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fg204/getFailinfo', { responseType: 'blob' })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    getFileList(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fg204/getFileList', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fg204InsertFile(formData) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fg204/insert', formData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fg204UpdateFile(formData) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fg204/update', formData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    fg204DeleteFile(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fg204/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    getNensaiList() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/nensailist')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fg202getMcodeList(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fg202/getMcodeList', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fg202Export(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fg202/export', request_data, { responseType: 'blob' })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    m1102ChangePassword(request_data) {
        return new Promise((resolve, reject) => {
            axios.post('/m1102/changePassword', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                })
        });
    }

    fd102Search(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fd102/search', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fd102Insert(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fd102/insert', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error.response.data);
                    reject(error.response.data);
                });
        });
    }

    fd102Update(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fd102/update', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fd102Delete(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fd102/delete', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fd102GetMaxLoginId() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fd102/getMaxLoginId')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }

    fd101Search(request_data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('AUTH_TOKEN');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.post('/fd101/search?page=', request_data)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log("error");
                    reject(error.response.data);
                });
        });
    }
}
