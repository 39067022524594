<script>
import AppLayout from "@/components/layouts/Layout.vue";
import moment from "moment";
import { APIService } from "@/apiService.js";
import datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const apiService = new APIService();
var numeral = require("numeral");

// インラインブーストラップローダでMaps JavaScript API を読み込む
((g) => {
  var h,
    a,
    k,
    p = "The Google Maps JavaScript API",
    c = "google",
    l = "importLibrary",
    q = "__ib__",
    m = document,
    b = window;
  b = b[c] || (b[c] = {});
  var d = b.maps || (b.maps = {}),
    r = new Set(),
    e = new URLSearchParams(),
    u = () =>
      h ||
      (h = new Promise(async (f, n) => {
        await (a = m.createElement("script"));
        e.set("libraries", [...r] + "");
        for (k in g)
          e.set(
            k.replace(/[A-Z]/g, (t) => "_" + t[0].toLowerCase()),
            g[k]
          );
        e.set("callback", c + ".maps." + q);
        a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
        d[q] = f;
        a.onerror = () => (h = n(Error(p + " could not load.")));
        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
        m.head.append(a);
      }));
  d[l] ? console.warn(p + " only loads once. Ignoring:", g) : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
})({
  key: "AIzaSyBXbD7jihEamvLDszKlaJigeq8CfU2WgaE",
  // Add other bootstrap parameters as needed, using camel case.
  // Use the 'v' parameter to indicate the version to load (alpha, beta, weekly, etc.)
});

export default {
  name: "fa102",
  components: {
    AppLayout,
    datepicker,
    Loading,
  },
  data() {
    if (this.$route.query.param_kubun == 1) {
      return {
        jpYear: "",
        max: "9",
        ja: ja,
        info: null,
        textError: null,
        kubun: this.$route.query.param_kubun,
        hou: "",
        seirinao: "",
        saigai_yyyy: this.$route.query.saigai_yyyy,
        saigai_ken: ("00000" + this.$route.query.saigai_ken).slice(-5),
        saigai_mei: this.$route.query.saigai_mei,
        kanrisya_cd: this.$route.query.kanrisya_cd === "null" ? "" : this.$route.query.kanrisya_cd,
        kanrisha_mei: this.$route.query.kanrisya_mei,
        login_id: this.$route.query.login_id,
        login_name: this.$route.query.login_name,
        kousyu_cd: "",
        jigyou_cd: this.$route.query.jigyou_cd,
        hisaibi: this.$route.query.hisaibi,
        valid_flg: this.$route.query.valid_flg,
        jigyoushutaimei: "",
        jigyou_mei: "",
        koushus: [],
        koushu: "",
        shichousons: [],
        shichouson: "",
        cyouazas: [],
        cyouaza: "",
        chisaki: "",
        rokyouryou_mei: "",
        kyouryou_required: false,
        sayugan_required: false,
        michikawakubuns: [],
        michikawakubun: "",
        michikawashubetsus: [],
        michikawashubetsu: "",
        michikawameis: [],
        michikawamei: "",
        shokan: this.$constants.FA102.DEFAULT_SHOKAN,
        kunitans: [],
        kunitan: this.$constants.FA102.DEFAULT_YOSAN_KBN,
        sayugans: [],
        sayugan: "",
        oukyukojis: [],
        oukyukoji: "",
        oukyukoji_kbns: [],
        oukyukoji_kbn: "",
        oukyukoji_start_day: "",
        oukyukoji_end_day: "",
        oukyukoji_houhous: [],
        oukyukoji_houhou: "",
        oukyukoji_houhou_etc: "",
        oukyukoji_houhou_sonota_selected: false,
        hisaijyous: [],
        hisaijyou: "",
        hisaijyou_etc: "",
        hisaijyou_sonota_selected: false,
        hisai_encyou: "",
        higai_gaku: "",
        higai_gaku_itaku: this.jigyou_cd == this.$constants.FA102.JIGYOU_SHI && localStorage.getItem("kengen_cd") !== this.$constants.KENGEN_CD.GENBA ? 0 : "",
        koushu_mei: "",
        shichouson_mei: "",
        cyouaza_mei: "",
        michikawakubun_mei: "",
        michikawashubetsu_mei: "",
        michikawa_mei: "",
        michikawa_txt: "",
        michikawa_required: false,
        michikawa_chk: this.$route.query.jigyou_cd == this.$constants.FA102.JIGYOU_SHI ? "2" : "1",
        shokan_mei: "",
        kuni_mei: "",
        sayugan_mei: "",
        oukyukoji_mei: "",
        oukyukoji_kb_mei: "",
        oukyukoji_houhou_mei: "",
        oukyukoji_houhou_cd: "",
        biko: "",
        hisaijyou_mei: "",
        koushinbi: null,
        isLoading: false,
        fullPage: true,
        status: 1,
        status_check: false,
        status_text_off: "",
        status_text_on: "",
        isTestDisabled: false,
        errors: {
          status: null,
          koushu: null,
          hisaibi: null,
          shichouson: null,
          cyouaza: null,
          hisai_encyou: null,
          higai_gaku: null,
          higai_gaku_itaku: null,
          rokasen_kbn: null,
          rokasen_syubetu: null,
          rokasen_cd: null,
          rokasen_txt: null,
          rokyouryou_mei: null,
          kunitan: null,
          hisaijyou_cd: null,
          oukyukoji: null,
          hisaijyou_etc: null,
          oukyukoji_houhou_etc: null,
          biko: null,
          itijoho: null,
          select_kanrisya: null,
        },
        error: null,
        showFlg: true,
        houkoku_status: "",
        bg_required: false,
        map: "",
        itijoho: null,
        gazou1: null,
        gazou2: null,
        gazou3: null,
        gazou1_name: "",
        gazou2_name: "",
        gazou3_name: "",
        kanrisyaList: [],
        select_kanrisya: localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA || localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.ADMIN ? "" : localStorage.getItem("Kanrisya_Cd"),
        admin_not_check: "",
        jigyoushutai: "",
        before_update_kanrisya_cd: "",
        kengenCheck: false,
      };
    } else {
      return {
        jpYear: "",
        max: null,
        ja: ja,
        info: null,
        textError: null,
        kubun: this.$route.query.param_kubun,
        hou: "",
        seirinao: this.$route.query.seirinao,
        saigai_yyyy: this.$route.query.saigai_yyyy,
        saigai_ken: ("00000" + this.$route.query.saigai_ken).slice(-5),
        saigai_mei: this.$route.query.saigai_mei,
        kanrisya_cd: this.$route.query.kanrisya_cd,
        kanrisha_mei: this.$route.query.kanrisya_mei,
        login_id: this.$route.query.login_id,
        login_name: this.$route.query.login_name,
        kousyu_cd: "",
        jigyou_cd: this.$route.query.jigyou_cd,
        hisaibi: this.$route.query.hisaibi,
        valid_flg: this.$route.query.valid_flg,
        jigyoushutaimei: "",
        jigyou_mei: "",
        koushus: [],
        koushu: "",
        shichousons: [],
        shichouson: "",
        cyouazas: [],
        cyouaza: "",
        chisaki: "",
        rokyouryou_mei: "",
        kyouryou_required: false,
        sayugan_required: false,
        michikawakubuns: [],
        michikawakubun: "",
        michikawashubetsus: [],
        michikawashubetsu: "",
        michikawameis: [],
        michikawamei: "",
        michikawa_txt: "",
        michikawa_chk: this.$route.query.jigyou_cd == this.$constants.FA102.JIGYOU_SHI ? "2" : "1",
        shokans: [],
        shokan: this.$constants.FA102.DEFAULT_SHOKAN,
        kunitans: [],
        kunitan: this.$constants.FA102.DEFAULT_YOSAN_KBN,
        sayugans: [],
        sayugan: "",
        oukyukojis: [],
        oukyukoji: "",
        oukyukoji_kbns: [],
        oukyukoji_kbn: "",
        oukyukoji_houhous: [],
        oukyukoji_houhou: "",
        oukyukoji_houhou_etc: "",
        oukyukoji_houhou_sonota_selected: false,
        hisaijyous: [],
        hisaijyou: "",
        hisaijyou_etc: "",
        hisaijyou_sonota_selected: false,
        hisai_encyou: "",
        higai_gaku: "",
        higai_gaku_itaku: "",
        koushu_mei: "",
        shichouson_mei: "",
        cyouaza_mei: "",
        michikawakubun_mei: "",
        michikawashubetsu_mei: "",
        michikawa_mei: "",
        michikawa_required: false,
        shokan_mei: "",
        kuni_mei: "",
        sayugan_mei: "",
        oukyukoji_mei: "",
        oukyukoji_kb_mei: "",
        oukyukoji_houhou_mei: "",
        oukyukoji_houhou_cd: "",
        oukyukoji_start_day: "",
        oukyukoji_end_day: "",
        biko: "",
        hisaijyou_mei: "",
        koushinbi: null,
        isLoading: false,
        fullPage: true,
        status: 1,
        status_check: false,
        status_text_off: "",
        status_text_on: "",
        isTestDisabled: false,
        errors: {
          status: null,
          koushu: null,
          hisaibi: null,
          shichouson: null,
          cyouaza: null,
          hisai_encyou: null,
          higai_gaku: null,
          higai_gaku_itaku: null,
          rokasen_kbn: null,
          rokasen_syubetu: null,
          rokasen_cd: null,
          rokasen_txt: null,
          rokyouryou_mei: null,
          kunitan: null,
          hisaijyou_cd: null,
          oukyukoji: null,
          hisaijyou_etc: null,
          oukyukoji_houhou_etc: null,
          biko: null,
          oukyukoji_start_day: null,
          oukyukoji_end_day: null,
          oukyukoji_kbn: null,
          oukyukoji_houhou: null,
          itijoho: null,
          select_kanrisya: null,
        },
        error: null,
        showFlg: true,
        chk_date: "",
        houkoku_status: "",
        admin: "",
        bg_required: false,
        map: "",
        itijoho: null,
        gazou1: null,
        gazou2: null,
        gazou3: null,
        gazou1_name: "",
        gazou2_name: "",
        gazou3_name: "",
        kanrisyaList: [],
        select_kanrisya: localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA || localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.ADMIN ? "" : localStorage.getItem("Kanrisya_Cd"),
        admin_not_check: "",
        jigyoushutai: "",
        before_update_kanrisya_cd: "",
        kengenCheck: false,
      };
    }
  },
  created() {
    this.authorityGyousha();
    this.show();
  },
  mounted() {
    this.getGengo(this.saigai_yyyy);
  },
  watch: {
    hisai_encyou: function (hisai_encyou) {
      var encyou = hisai_encyou
        // remove bad characters and multiple points
        .replace(/[^\d.]|\.(?=.*\.)/, "")
        // remove any excess of 8 integer digits
        .replace(/^(\d{8})\d+/, "$1")
        // remove any excess of 2 decimal digits
        .replace(/(\.\d\d).+/, "$1");
      if (encyou !== hisai_encyou) {
        // Only if something had to be fixed, update
        hisai_encyou = encyou;
      }
      this.max = hisai_encyou.indexOf(".") > 0 ? 11 : 9;
    },
  },
  methods: {
    getGengo(year) {
      this.info = "";
      this.isHasError = false;
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param,
      };
      apiService.getGengo(request_data).then((result) => {
        this.jpYear = result;
      });
    },
    show() {
      this.isLoading = true;
      var request_data = {
        seirinao: this.seirinao,
        saigai_yyyy: this.$route.query.saigai_yyyy,
        saigai_ken: this.$route.query.saigai_ken,
        kanrisya_cd: this.$route.query.kanrisya_cd,
        kengen_cd: localStorage.getItem("kengen_cd"),
        login_kanrisya: localStorage.getItem("Kanrisya_Cd"),
        shicyou_kbn: localStorage.getItem("shicyou_kbn"),
        valid_flg: this.$route.query.valid_flg,
      };
      apiService.fa102Detail(request_data).then((result) => {
        var hou_data = null;
        if (result.hou != null && result.hou.length > 0) {
          hou_data = result.hou[0];
        }
        this.houkoku_status = hou_data ? hou_data.houkoku_status : null;
        this.hou = "只今，入力ができない状態です。";
        if (hou_data != null && hou_data.houkoku_status == "1") {
          this.hou = "只今，【第" + hou_data.hou + "報】入力期間となります。";
        } else if (hou_data != null && hou_data.houkoku_status == "2") {
          this.hou = "【第" + hou_data.hou + "報】入力は只今，締め切られています。";
        }
        var authorityAdmin = this.authorityAdmin();
        if (authorityAdmin) {
          this.admin = 1;
        } else {
          this.admin = 0;
        }
        // 事業主体が市町村工事の場合、被害報告額（委託費）に0を入力
        if (this.jigyou_cd == this.$constants.FA102.JIGYOU_SHI && localStorage.getItem("kengen_cd") !== this.$constants.KENGEN_CD.GENBA) {
          this.higai_gaku_itaku = 0;
        } else {
          this.higai_gaku_itaku = "";
        }
        this.koushus = [];
        for (var a in result.koushu) {
          this.koushus.push({
            value: result.koushu[a].cd_value,
            key: result.koushu[a].meisai,
          });
        }
        // 報告データが仮登録の場合、メッセージを表示
        this.admin_not_check = "";
        this.admin_not_check = "データは管理者が確認していません。";

        // 管理者・被災現場確認かの確認
        if (localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA || localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.ADMIN) {
          this.kengenCheck = false;
        } else {
          this.kengenCheck = true;
        }

        // 管理者リスト取得
        this.kanrisyaList = [];
        for (var i in result.kanrisya) {
          if (result.kanrisya[0].kanrisya_cd === 0) {
            result.kanrisya.shift();
          }
          this.kanrisyaList.push({
            value: result.kanrisya[i].kanrisya_mei,
            key: result.kanrisya[i].kanrisya_cd,
            shicyou_kbn: result.kanrisya[i].shicyou_kbn,
          });
        }
        var shicyou_exist = false;
        this.shichousons = [];
        for (var b in result.shichouson) {
          this.shichousons.push({
            value: result.shichouson[b].shicyou_mei,
            key: result.shichouson[b].shicyou_cd,
          });
          if (result.detail.length > 0 && result.detail[0].shicyou_cd == result.shichouson[b].shicyou_cd) {
            shicyou_exist = true;
          }
        }
        // 上位権限のユーザが現ユーザで選択できない市町村を設定した場合、
        // リストにその市町村を追加する。
        if (result.detail.length > 0 && !shicyou_exist) {
          this.shichousons.push({
            value: result.detail[0].shicyou_mei,
            key: result.detail[0].shicyou_cd,
          });
        }
        this.michikawakubuns = [];
        for (var c in result.michikawakubun) {
          this.michikawakubuns.push({
            value: result.michikawakubun[c].cd_value,
            key: result.michikawakubun[c].meisai,
          });
        }
        this.kunitans = [];
        for (var e in result.kunitan) {
          this.kunitans.push({
            value: result.kunitan[e].cd_value,
            key: result.kunitan[e].meisai,
          });
        }
        this.sayugans = [];
        for (var f in result.sayugan) {
          this.sayugans.push({
            value: result.sayugan[f].cd_value,
            key: result.sayugan[f].meisai,
          });
        }
        this.oukyukojis = [];
        for (var g in result.oukyukoji) {
          this.oukyukojis.push({
            value: result.oukyukoji[g].cd_value,
            key: result.oukyukoji[g].meisai,
          });
        }
        this.oukyukoji_kbns = [];
        for (var h in result.oukyukoji_kbn) {
          this.oukyukoji_kbns.push({
            value: result.oukyukoji_kbn[h].cd_value,
            key: result.oukyukoji_kbn[h].meisai,
          });
        }
        this.oukyukoji_houhous = [];
        for (var j in result.oukyukoji_houhou) {
          this.oukyukoji_houhous.push({
            value: result.oukyukoji_houhou[j].oukyukoji_houhou_mei,
            key: result.oukyukoji_houhou[j].oukyukoji_houhou,
          });
        }
        // fa101で既存データを選択した場合
        if (this.kubun == 2) {
          this.status = result.detail[0].status;
          this.status_check = this.status == 2 ? true : false;
          this.statusCheck();
          this.koushu_mei = result.detail[0].kosyu_mei;
          this.jigyoushutaimei = result.detail[0].jigyou_mei;
          this.shichouson_mei = result.detail[0].shicyou_mei;
          this.before_update_kanrisya_cd = result.detail[0].kanrisya_cd;
          this.select_kanrisya = result.detail[0].kanrisya_cd;
          this.cyouaza_mei = result.detail[0].cyoaza_mei;
          this.chisaki = result.detail[0].chisaki;
          this.michikawakubun_mei = result.detail[0].cd_value;
          this.michikawashubetsu_mei = result.detail[0].kasen_syubetumei;
          if (result.detail[0].gazou1 !== null) {
            this.gazou1_name = result.detail[0].gazou1.substr(result.detail[0].gazou1.indexOf("/") + 1);
          }
          if (result.detail[0].gazou2 !== null) {
            this.gazou2_name = result.detail[0].gazou2.substr(result.detail[0].gazou2.indexOf("/") + 1);
          }
          if (result.detail[0].gazou3 !== null) {
            this.gazou3_name = result.detail[0].gazou3.substr(result.detail[0].gazou3.indexOf("/") + 1);
          }
          if (!result.detail[0].rokasen_cd) {
            this.michikawa_txt = result.detail[0].rokasen_mei;
            this.michikawa_mei = result.detail[0].rokasen_mei;
            if (!result.detail[0].rokasen_mei) {
              this.michikawa_chk = "1";
            } else {
              this.michikawa_chk = "2";
            }
          } else {
            this.michikawamei = result.detail[0].rokasen_cd;
            this.michikawa_mei = result.detail[0].rokasen_mei;
            this.michikawa_chk = "1";
          }
          //市町村工事の場合は直接入力選択する。
          // if(this.$route.query.jigyou_cd == this.$constants.FA102.JIGYOU_SHI) {
          //   this.michikawa_chk = "2";
          // }
          this.michikawa_mei = result.detail[0].rokasen_mei;
          this.rokyouryou_mei = result.detail[0].rokyouryou_mei;
          this.kunitan = result.detail[0].kuni_tan;
          this.kuni_mei = result.detail[0].kuni_mei;
          this.sayugan_mei = result.detail[0].sayugan_mei;
          this.oukyukoji_mei = result.detail[0].oukyukoji_mei;
          this.oukyukoji_kbn_mei = result.detail[0].oukyukoji_kbn_mei;
          this.oukyukoji_houhou_mei = result.detail[0].oukyukoji_houhou_mei;
          this.oukyukoji_houhou_cd = result.detail[0].oukyukoji_houhou_cd;
          this.hisaijyou_mei = result.detail[0].hisaijyou_mei;
          this.hisai_encyou = this.formatDecimal(result.detail[0].hisai_encyou);
          //this.oukyukoji_start_day = this.oukyukoji_start_day ? moment(String(result.detail[0].oukyukoji_start_day)).format("YYYY-MM-DD") : null;
          //this.oukyukoji_end_day = this.oukyukoji_end_day ? moment(String(result.detail[0].oukyukoji_end_day)).format("YYYY-MM-DD") : null;

          this.oukyukoji_start_day = result.detail[0].oukyukoji_start_day ? moment(String(result.detail[0].oukyukoji_start_day)).format("YYYY-MM-DD") : null;
          this.oukyukoji_end_day = result.detail[0].oukyukoji_end_day ? moment(String(result.detail[0].oukyukoji_end_day)).format("YYYY-MM-DD") : null;

          this.koushinbi = result.detail[0].koushinbi;

          this.higai_gaku = this.formatNumber(result.detail[0].higai_gaku).toString().split(".")[0];
          this.higai_gaku_itaku = this.formatNumber(result.detail[0].higai_gaku_itaku).toString().split(".")[0];
          //市町村工事の場合は0
          // if(this.$route.query.jigyou_cd == this.$constants.FA102.JIGYOU_SHI) {
          //   this.higai_gaku_itaku = 0;
          // }

          this.hisaijyou_etc = result.detail[0].hisaijyou_etc;
          this.oukyukoji_houhou_etc = result.detail[0].oukyukoji_houhou_etc;
          this.biko = result.detail[0].biko;

          this.itijoho = result.detail[0].itijoho;
          this.showMap();
          // this.gazou1 = result.detail[0].gazou1;
          // this.gazou2 = result.detail[0].gazou2;
          // this.gazou3 = result.detail[0].gazou3;

          var koushu_id = this.koushus.filter((i) => i.value == this.koushu_mei);
          this.koushu = koushu_id[0].key;
          this.kyouryou_required = this.koushu == this.$constants.FA102.KOSYU_KYORYO ? true : false;
          this.sayugan_required = this.koushu == this.$constants.FA102.KOSYU_KASEN ? true : false;

          //var kuni_id = this.kunitans.filter(i => i.value == this.kuni_mei);
          //this.kunitan = kuni_id.lenght > 0 ? kuni_id[0].key : "";

          var hisaijyou_id = this.hisaijyous.filter((i) => i.value == this.hisaijyou_mei);
          this.hisaijyou = hisaijyou_id.length > 0 ? hisaijyou_id[0].key : "";

          var sayugan_id = this.sayugans.filter((i) => i.value == this.sayugan_mei);
          this.sayugan = sayugan_id.length > 0 ? sayugan_id[0].key : "";

          var oukyukoji_id = this.oukyukojis.filter((i) => i.value == this.oukyukoji_mei);
          this.oukyukoji = oukyukoji_id.length > 0 ? oukyukoji_id[0].key : "";
          if (this.oukyukoji == 2 || this.oukyukoji == 3) {
            this.bg_required = true;
          } else {
            this.bg_required = false;
          }

          var oukyukoji_kbn_id = this.oukyukoji_kbns.filter((i) => i.value == this.oukyukoji_kbn_mei);
          this.oukyukoji_kbn = oukyukoji_kbn_id.length > 0 ? oukyukoji_kbn_id[0].key : "";

          var oukyukoji_houhou_id = this.oukyukoji_houhous.filter((i) => i.key == this.oukyukoji_houhou_cd);
          this.oukyukoji_houhou = oukyukoji_houhou_id.length > 0 ? oukyukoji_houhou_id[0].key : "";

          var hisai_data = {
            kousyu_cd: this.koushu,
          };
          apiService
            .fa102GetHisaijoumei(hisai_data)
            .then((result) => {
              this.hisaijyous = [];
              for (var h in result) {
                this.hisaijyous.push({
                  value: result[h].hisaijyou_mei,
                  key: result[h].hisaijyou_cd,
                });
              }
              var hisaijyou_id = this.hisaijyous.filter((i) => i.value == this.hisaijyou_mei);
              this.hisaijyou = hisaijyou_id.length > 0 ? hisaijyou_id[0].key : "";
              this.hisaijyou_sonota_selected = this.hisaijyou == Number(this.$constants.FA102.HISAIJYOU_CD_OTHER) ? true : false;
            })
            .catch((error) => {
              this.error = error;
            });
          var oukyukoji_houhou_data = {
            kousyu_cd: this.koushu,
          };
          apiService
            .fa102GetOukyukojiHouhou(oukyukoji_houhou_data)
            .then((result) => {
              this.oukyukoji_houhous = [];
              for (var h in result) {
                this.oukyukoji_houhous.push({
                  value: result[h].oukyukoji_houhou_mei,
                  key: result[h].oukyukoji_houhou,
                });
              }
              var oukyukoji_houhou_id = this.oukyukoji_houhous.filter((i) => i.key == this.oukyukoji_houhou_cd);
              this.oukyukoji_houhou = oukyukoji_houhou_id.length > 0 ? oukyukoji_houhou_id[0].key : "";
              this.oukyukoji_houhou_sonota_selected = this.oukyukoji_houhou == Number(this.$constants.FA102.OUKYUKOJI_HOUHOU_OTHER) ? true : false;
            })
            .catch((error) => {
              this.error = error;
            });
          var shinchou_id = this.shichousons.filter((i) => i.value == this.shichouson_mei);
          this.shichouson = shinchou_id[0].key;
          var cyouaza_data = {
            kode_kbn: shinchou_id[0].key,
          };
          apiService
            .fa102GetCyouazamei(cyouaza_data)
            .then((result) => {
              this.cyouazas = [];
              for (var c in result) {
                this.cyouazas.push({
                  value: result[c].cyouaza_mei,
                  key: result[c].cyouaza_cd,
                });
              }
              var cyouaza_id = this.cyouazas.filter((i) => i.value == this.cyouaza_mei);
              this.cyouaza = cyouaza_id[0].key;
            })
            .catch((error) => {
              this.error = error;
            });

          if (this.michikawakubun_mei) {
            var kubun_id = this.michikawakubuns.filter((i) => i.value == this.michikawakubun_mei);
            this.michikawakubun = kubun_id[0].key;
            var shubetu_data = {
              kubun_cd: kubun_id[0].key,
            };
            apiService
              .fa102GetKasenshubetsumei(shubetu_data)
              .then((result) => {
                this.michikawashubetsus = [];
                for (var s in result) {
                  this.michikawashubetsus.push({
                    value: result[s].kasen_syubetumei,
                    key: result[s].kasen_syubetu,
                  });
                }
                var shubetu_id = this.michikawashubetsus.filter((i) => i.value == this.michikawashubetsu_mei);
                this.michikawashubetsu = shubetu_id[0].key;
                var rokasen_data = {
                  kasen_kbn: kubun_id[0].key,
                  kasen_syubetu: shubetu_id[0].key,
                };
                apiService
                  .fa102GetRokasenmei(rokasen_data)
                  .then((result) => {
                    this.michikawameis = [];
                    if (result != null && result.length > 0) {
                      for (var r in result) {
                        this.michikawameis.push({
                          value: result[r].rokasen_mei,
                          key: result[r].kasen_cd,
                        });
                      }
                      this.michikawa_required = true;
                    } else {
                      this.michikawa_required = false;
                    }
                    var michikawamei_id = this.michikawameis.filter((i) => i.key == this.michikawamei);
                    this.michikawa_mei = michikawamei_id[0].value;
                    this.isLoading = false;
                  })
                  .catch((error) => {
                    this.isLoading = false;
                    this.error = error;
                  });
              })
              .catch((error) => {
                this.isLoading = false;
                this.error = error;
              });
          } else {
            this.isLoading = false;
          }
          this.isLoading = false;
        } else {
          var request_data = {
            saigai_yyyy: this.$route.query.saigai_yyyy,
            saigai_ken: this.$route.query.saigai_ken,
          };
          this.status_check = this.status == 2 ? true : false;
          this.statusCheck();
          this.showMap();
          apiService
            .fa102GetHisaibi(request_data)
            .then((result) => {
              this.hisaibi = moment(String(result.hisaibi)).format("YYYY-MM-DD");
            })
            .catch((error) => {
              this.error = error;
            });
          //this.jigyou_cd = this.$route.query.jigyou_cd;
          this.isLoading = false;
        }
        this.isLoading = false;
        // 排他チェック用日付
        this.chk_date = result.chk_date;
      });
    },
    authorityGyousha() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.GENBA) {
        this.isTestDisabled = true;
      } else {
        this.isTestDisabled = false;
      }
    },
    kanrisyaChange(select_kanrisya) {
      this.michikawamei = "";
      this.michikawa_txt = "";
      this.select_kanrisya = select_kanrisya;
      // 選択した管理者から事業主体を決定
      var shicyou_kbn = this.kanrisyaList.filter((i) => i.key == this.select_kanrisya);
      this.jigyou_cd = shicyou_kbn[0].shicyou_kbn == this.$constants.JIGYOU_CD.JIGYOU_CD2 ? this.$constants.JIGYOU_CD.JIGYOU_CD2 : this.$constants.JIGYOU_CD.JIGYOU_CD1;
      // 事業主体に応じて路河川名の入力欄コントロール
      if (this.jigyou_cd === this.$constants.JIGYOU_CD.JIGYOU_CD2) {
        this.michikawa_chk = "2";
      } else {
        this.michikawa_chk = "1";
      }
      // 事業主体が市町村工事の場合、被害報告額（委託費）に0を入力
      if (this.jigyou_cd == this.$constants.FA102.JIGYOU_SHI && localStorage.getItem("kengen_cd") !== this.$constants.KENGEN_CD.GENBA) {
        this.higai_gaku_itaku = 0;
      } else {
        this.higai_gaku_itaku = "";
      }
      var request_data = {
        login_kanrisya: select_kanrisya,
        shicyou_kbn: shicyou_kbn[0].shicyou_kbn,
      };
      apiService
        .fa102GetShityousonList(request_data)
        .then((result) => {
          this.shichousons = [];
          for (var c in result) {
            this.shichousons.push({
              value: result[c].shicyou_mei,
              key: result[c].shicyou_cd,
            });
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    shichousonsChange(shichouson) {
      this.shichouson = shichouson;
      var shocyou_id = this.shichousons.filter((i) => i.key == shichouson);
      this.shichouson_mei = shocyou_id[0].value;
      var request_data = {
        kode_kbn: shichouson,
      };
      apiService
        .fa102GetCyouazamei(request_data)
        .then((result) => {
          this.cyouazas = [];
          this.cyouaza = "";
          this.cyouaza_mei = "";
          for (var c in result) {
            this.cyouazas.push({
              value: result[c].cyouaza_mei,
              key: result[c].cyouaza_cd,
            });
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    michikawakubunsChange(michikawakubun) {
      // 区分変更時、路河川種別と路河川名クリア
      this.michikawashubetsus = [];
      this.michikawashubetsu = "";
      this.michikawashubetsu_mei = "";
      this.michikawameis = [];
      this.michikawamei = "";
      this.michikawa_mei = "";

      this.michikawakubun = michikawakubun;
      var kubun_id = this.michikawakubuns.filter((i) => i.key == michikawakubun);
      this.michikawakubun_mei = kubun_id[0].value;
      var request_data = {
        kubun_cd: michikawakubun,
      };
      apiService
        .fa102GetKasenshubetsumei(request_data)
        .then((result) => {
          for (var s in result) {
            this.michikawashubetsus.push({
              value: result[s].kasen_syubetumei,
              key: result[s].kasen_syubetu,
            });
          }
          this.michikawashubetsu = "";
          var shubetu_id = this.michikawashubetsus.filter((i) => i.key == this.michikawashubetsu);
          this.michikawashubetsu_mei = shubetu_id[0].value;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    michikawashubetsuChange(michikawashubetsu) {
      this.michikawashubetsu = michikawashubetsu;
      var request_data = {
        kasen_kbn: this.michikawakubun,
        kasen_syubetu: michikawashubetsu,
      };
      apiService
        .fa102GetRokasenmei(request_data)
        .then((result) => {
          this.michikawameis = [];
          this.michikawamei = "";
          this.michikawa_mei = "";
          if (result != null && result.length > 0) {
            for (var r in result) {
              this.michikawameis.push({
                value: result[r].rokasen_mei,
                key: result[r].kasen_cd,
              });
            }
            this.michikawa_required = true;
          } else {
            this.michikawa_required = false;
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    statusCheck() {
      this.status = this.status_check ? 2 : 1;
      this.status_text_off = this.status == 2 ? "仮登録" : "本登録";
      this.status_text_on = this.status == 2 ? "本登録" : "仮登録";
    },
    koushusChange(koushu) {
      this.koushu = koushu;
      var koushu_id = this.koushus.filter((i) => i.key == koushu);
      this.kyouryou_required = this.koushu == this.$constants.FA102.KOSYU_KYORYO ? true : false;
      this.sayugan_required = this.koushu == this.$constants.FA102.KOSYU_KASEN ? true : false;
      this.koushu_mei = koushu_id.length > 0 ? koushu_id[0].value : "";
      var request_data = {
        kousyu_cd: koushu,
      };
      apiService
        .fa102GetHisaijoumei(request_data)
        .then((result) => {
          this.hisaijyous = [];
          this.hisaijyou = "";
          for (var h in result) {
            this.hisaijyous.push({
              value: result[h].hisaijyou_mei,
              key: result[h].hisaijyou_cd,
            });
          }
          this.hisaijyou = "";
          this.hisaijyou_sonota_selected = false;
        })
        .catch((error) => {
          this.error = error;
        });
      apiService
        .fa102GetOukyukojiHouhou(request_data)
        .then((result) => {
          this.oukyukoji_houhous = [];
          this.oukyukoji_houhou = "";
          for (var h in result) {
            this.oukyukoji_houhous.push({
              value: result[h].oukyukoji_houhou_mei,
              key: result[h].oukyukoji_houhou,
            });
          }
          this.oukyukoji_houhou = "";
          this.oukyukoji_houhou_sonota_selected = false;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    cyouazasChange(cyouaza) {
      this.cyouaza = cyouaza;
      var cyouaza_id = this.cyouazas.filter((i) => i.key == cyouaza);
      this.cyouaza_mei = cyouaza_id[0].value;
    },
    michikawameiChange(michimei) {
      this.michimei = michimei;
      var michikawa_id = this.michikawameis.filter((i) => i.key == michimei);
      this.michikawa_mei = michikawa_id[0].value;
    },
    kunitanChange(kunitan) {
      this.kunitan = kunitan;
      var kuni_id = this.kunitans.filter((i) => i.key == kunitan);
      this.kuni_mei = kuni_id[0].value;
    },
    hisaijyouChange(hisaijyou) {
      this.hisaijyou = hisaijyou;
      var hisai_id = this.hisaijyous.filter((i) => i.key == hisaijyou);
      this.hisaijyou_mei = hisai_id[0].value;
      this.hisaijyou_sonota_selected = this.hisaijyou == this.$constants.FA102.HISAIJYOU_CD_OTHER ? true : false;
    },
    oukyukojiHouhousChange(oukyukoji_houhou) {
      this.oukyukoji_houhou = oukyukoji_houhou;
      this.oukyukoji_houhou_sonota_selected = this.oukyukoji_houhou == this.$constants.FA102.OUKYUKOJI_HOUHOU_OTHER ? true : false;
    },
    formatDecimal: function (number) {
      return number == "" || number == null || numeral(number).format("0,0.0") == 0 ? "" : numeral(number).format("0,0.0");
    },
    formatNumber: function (number) {
      return number == "" || number == null || numeral(number).format("0,0") == 0 ? 0 : numeral(number).format("0,0");
    },
    hisaiChange(hisai_encyou) {
      this.hisai_encyou = this.replaceComma(hisai_encyou);
      if (this.isFloat(this.hisai_encyou)) {
        this.hisai_encyou = this.hisai_encyou.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    hisaiFocus() {
      this.hisai_encyou = this.replaceComma(this.hisai_encyou);
    },
    higaiChange(higai_gaku) {
      this.higai_gaku = this.replaceComma(higai_gaku);
      if (this.isInteger(this.higai_gaku)) {
        this.higai_gaku = this.higai_gaku.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    higaiFocus() {
      this.higai_gaku = this.replaceComma(this.higai_gaku);
    },
    higaiItakuChange(higai_gaku_itaku) {
      this.higai_gaku_itaku = this.replaceComma(higai_gaku_itaku);
      if (this.isInteger(this.higai_gaku_itaku)) {
        this.higai_gaku_itaku = this.higai_gaku_itaku.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    higaiItakuFocus() {
      this.higai_gaku_itaku = this.replaceComma(this.higai_gaku_itaku);
    },
    replaceComma(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    isFloat(value) {
      var float = /^\d+\.\d+$/.test(value);
      var int = /^\d+$/.test(value);
      if (float || int) {
        return true;
      }
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },

    oukyukojiChange(oukyukoji) {
      //this.oukyukoji = oukyukoji;
      if (oukyukoji == 2 || oukyukoji == 3) {
        this.bg_required = true;
      } else {
        this.bg_required = false;
      }
    },
    async showMap() {
      var latitude = "";
      var longitude = "";

      if (this.itijoho === null || this.itijoho === "") {
        latitude = Number(36.341591139835714);
        longitude = Number(140.4467461048239);
        this.itijoho = null;
      } else {
        latitude = Number(this.itijoho.substring(0, this.itijoho.indexOf(",")));
        longitude = Number(this.itijoho.substring(this.itijoho.indexOf(",") + 1));
        if (isNaN(latitude) || isNaN(longitude)) {
          return;
        }
      }

      var marker = [];
      var self = this;
      initMap(this.itijoho);

      async function initMap(itijoho) {
        const { Map } = await window.google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");

        const myLatLng = new window.google.maps.LatLng({ lat: Number(latitude), lng: Number(longitude) });
        // 地図の設定と作成
        const map = new Map(document.getElementById("mapArea"), {
          center: myLatLng,
          zoom: 16,
          mapId: "28ae36fad9a1de3a",
        });

        if (itijoho !== null) {
          // マーカーの設定と作成
          marker = new AdvancedMarkerElement({
            position: myLatLng,
            map,
          });
        }

        // クリックイベントを追加
        map.addListener("click", function (e) {
          if (confirm(self.$messages.msg["0017E"])) {
            getClickLatLng(e.latLng, map);
          }
        });
      }

      async function getClickLatLng(lat_lng, map) {
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
        //既にあるマーカーを一旦削除
        deleteMakers();
        // 座標を表示
        self.itijoho = lat_lng.lat() + "," + lat_lng.lng();

        // マーカーの設定と作成
        marker = new AdvancedMarkerElement({
          position: lat_lng,
          map,
        });

        // 座標の中心をずらす
        map.panTo(lat_lng);
      }

      function deleteMakers() {
        marker.map = null;
      }
    },
    fileSelected1(e) {
      this.isLoading = true;
      this.clearError();
      this.textError = null;
      this.info = null;
      this.gazou1 = e.target.files[0];

      // 位置情報が入力されている場合、位置情報は取得しない。
      if (this.itijoho === "") {
        this.itijoho = null;
      }
      if (this.itijoho !== null) {
        this.isLoading = false;
        return;
      }

      // 位置情報取得のためsystem側に転送
      let formData = new FormData();
      formData.append("image", this.gazou1);
      apiService
        .fa102getItijoho(formData)
        .then((resutl) => {
          this.itijoho = resutl;
          this.showMap();
        })
        .catch((error) => {
          this.errors.itijoho = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fileSelected2(e) {
      this.gazou2 = e.target.files[0];
    },
    fileSelected3(e) {
      this.gazou3 = e.target.files[0];
    },
    insert_btn() {
      //被災報告額(工事費)が1000000以上なら警告
      if (this.replaceComma(this.higai_gaku) >= 1000000) {
        if (!confirm(this.$messages.msg["0002Q"])) {
          return false;
        }
      }

      if (this.replaceComma(this.higai_gaku_itaku) >= 1000000) {
        if (!confirm(this.$messages.msg["0003Q"])) {
          return false;
        }
      }
      this.isLoading = true;
      this.clearError();
      this.textError = null;
      this.info = null;

      var request_data = {
        kanrisya_cd: this.select_kanrisya,
        saigai_yyyy: this.$route.query.saigai_yyyy,
        kbn: this.$route.query.kbn,
      };

      if (this.oukyukoji == 2 || this.oukyukoji == 3) {
        var oukyu_error_flg = false;
        if (!this.oukyukoji_kbn) {
          this.errors.oukyukoji_kbn = "応急工事の区分を入力して下さい。";
          this.isLoading = false;
          oukyu_error_flg = true;
        }
        if (!this.oukyukoji_houhou) {
          this.errors.oukyukoji_houhou = "応急工事の方法を入力して下さい。";
          this.isLoading = false;
          oukyu_error_flg = true;
        }
        if (!this.oukyukoji_start_day) {
          this.errors.oukyukoji_start_day = "応急工事着手日を入力して下さい。";
          this.isLoading = false;
          oukyu_error_flg = true;
        }
        if (!this.oukyukoji_end_day) {
          this.errors.oukyukoji_end_day = "応急工事完了日を入力して下さい。";
          this.isLoading = false;
          oukyu_error_flg = true;
        }
        if (oukyu_error_flg) {
          return false;
        }
      }

      apiService
        .fa102GetSeiri(request_data)
        .then((result) => {
          this.seirinao = result.seirinao;

          var request_data = {
            kbn: this.$route.query.kbn,
            seirinao: result.seirinao,
            // kanrisya_cd: localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA ? this.select_kanrisya : this.$route.query.kanrisya_cd,
            kanrisya_cd: this.select_kanrisya,
            saigai_yyyy: this.$route.query.saigai_yyyy,
            saigai_ken: this.$route.query.saigai_ken,
            kengen_cd: localStorage.getItem("kengen_cd"),
            saigai_mei: this.saigai_mei,
            koushu: this.koushu,
            jigyou_cd: this.jigyou_cd,
            hisaibi: this.hisaibi ? moment(String(this.hisaibi)).format("YYYY-MM-DD") : null,
            shichouson: this.shichouson,
            cyouaza: this.cyouaza,
            chisaki: this.chisaki,
            rokasen_kbn: this.michikawakubun,
            rokasen_syubetu: this.michikawashubetsu,
            rokasen_cd: this.michikawamei,
            rokasen_mei: this.michikawa_mei,
            rokasen_txt: this.michikawa_txt,
            rokasen_chk: this.michikawa_chk,
            rokyouryou_mei: this.rokyouryou_mei,
            syokan_cd: this.shokan,
            kunitan: this.kunitan,
            hisaijyou_cd: this.hisaijyou,
            hisai_encyou: this.replaceComma(this.hisai_encyou),
            higai_gaku: this.replaceComma(this.higai_gaku),
            higai_gaku_itaku: this.replaceComma(this.higai_gaku_itaku),
            sayugan: this.sayugan,
            oukyukoji: this.oukyukoji,
            oukyukoji_kbn: this.oukyukoji_kbn,
            oukyukoji_start_day: this.oukyukoji_start_day ? moment(String(this.oukyukoji_start_day)).format("YYYY-MM-DD") : null,
            oukyukoji_end_day: this.oukyukoji_end_day ? moment(String(this.oukyukoji_end_day)).format("YYYY-MM-DD") : null,
            oukyukoji_houhou: this.oukyukoji_houhou,
            oukyukoji_houhou_etc: this.oukyukoji_houhou_etc,
            hisaijyou_etc: this.hisaijyou_etc,
            biko: this.biko,
            kasen_kbn: this.michikawakubun,
            kasen_syubetu: this.michikawashubetsu,
            loginId: localStorage.getItem("LOGIN_ID"),
            koushinbi: this.koushinbi,
            status: this.status,
            itijoho: this.itijoho,
          };

          let formData = new FormData();
          if (this.gazou1 !== null) {
            if (typeof this.gazou1 !== "undefined") {
              formData.append("image1", this.gazou1);
            } else {
              this.gazou1 = null;
            }
          }
          if (this.gazou2 !== null) {
            if (typeof this.gazou2 !== "undefined") {
              formData.append("image2", this.gazou2);
            } else {
              this.gazou2 = null;
            }
          }
          if (this.gazou3 !== null) {
            if (typeof this.gazou3 !== "undefined") {
              formData.append("image3", this.gazou3);
            } else {
              this.gazou3 = null;
            }
          }
          formData.append("kanrisya_cd", localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA ? this.select_kanrisya : this.$route.query.kanrisya_cd);
          formData.append("saigai_yyyy", this.$route.query.saigai_yyyy);
          formData.append("saigai_ken", this.$route.query.saigai_ken);
          formData.append("kbn", this.$route.query.kbn);
          formData.append("seirinao", this.seirinao);

          apiService
            .fa102Insert(request_data)
            .then((result) => {
              this.info = result;
              this.kubun = 1;
              if (!(this.gazou1 === null && this.gazou2 === null && this.gazou3 === null)) {
                apiService
                  .fa102UpdateFile(formData)
                  .then(() => {
                    this.clearData();
                    this.show();
                  })
                  .catch((error) => {
                    if (typeof error == "string") {
                      confirm(this.$messages.msg["0013E"]);
                      this.textError = error;
                    }
                  });
              } else {
                this.clearData();
                this.show();
              }
            })
            .catch((error) => {
              this.seirinao = "";
              if (typeof error == "string") {
                confirm(this.$messages.msg["0013E"]);
                this.textError = error;
              }
              if (error.errors != null) {
                this.errors.itijoho = error.errors.itijoho ? error.errors.itijoho[0] : "";
                this.errors.koushu = error.errors.koushu ? error.errors.koushu[0] : "";
                this.errors.hisaibi = error.errors.hisaibi ? error.errors.hisaibi[0] : "";
                this.errors.shichouson = error.errors.shichouson ? error.errors.shichouson[0] : "";
                this.errors.cyouaza = error.errors.cyouaza ? error.errors.cyouaza[0] : "";
                this.errors.hisai_encyou = error.errors.hisai_encyou ? error.errors.hisai_encyou[0] : "";
                this.errors.higai_gaku = error.errors.higai_gaku ? error.errors.higai_gaku[0] : "";
                this.errors.higai_gaku_itaku = error.errors.higai_gaku_itaku ? error.errors.higai_gaku_itaku[0] : "";
                this.errors.rokasen_kbn = error.errors.rokasen_kbn ? error.errors.rokasen_kbn[0] : "";
                this.errors.rokasen_syubetu = error.errors.rokasen_syubetu ? error.errors.rokasen_syubetu[0] : "";
                this.errors.rokasen_cd = error.errors.rokasen_cd ? error.errors.rokasen_cd[0] : "";
                this.errors.rokasen_txt = error.errors.rokasen_txt ? error.errors.rokasen_txt[0] : "";
                this.errors.rokyouryou_mei = error.errors.rokyouryou_mei ? error.errors.rokyouryou_mei[0] : "";
                this.errors.kunitan = error.errors.kunitan ? error.errors.kunitan[0] : "";
                this.errors.hisaijyou_cd = error.errors.hisaijyou_cd ? error.errors.hisaijyou_cd[0] : "";
                this.errors.oukyukoji = error.errors.oukyukoji ? error.errors.oukyukoji[0] : "";
                this.errors.hisaijyou_etc = error.errors.hisaijyou_etc ? error.errors.hisaijyou_etc[0] : "";
                this.errors.oukyukoji_houhou_etc = error.errors.oukyukoji_houhou_etc ? error.errors.oukyukoji_houhou_etc[0] : "";
                this.errors.sayugan = error.errors.sayugan ? error.errors.sayugan[0] : "";
                this.errors.select_kanrisya = error.errors.select_kanrisya ? error.errors.select_kanrisya[0] : "";
                confirm(this.$messages.msg["0013E"]);
              }
            });

          this.isLoading = false;
          scrollTo(0, 0);
        })
        .catch((error) => {
          if (typeof error == "string") {
            this.textError = error;
            scrollTo(0, 0);
          }
          this.isLoading = false;
        });
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    update_btn() {
      //被災報告額(工事費)が1000000以上なら警告
      if (this.replaceComma(this.higai_gaku) >= 1000000) {
        if (!confirm(this.$messages.msg["0002Q"])) {
          return false;
        }
      }

      if (this.replaceComma(this.higai_gaku_itaku) >= 1000000) {
        if (!confirm(this.$messages.msg["0003Q"])) {
          return false;
        }
      }

      this.isLoading = true;
      this.clearError();
      this.textError = null;
      this.info = null;

      let formData = new FormData();
      if (this.gazou1 !== null) {
        if (typeof this.gazou1 !== "undefined") {
          formData.append("image1", this.gazou1);
        } else {
          this.gazou1 = null;
        }
      }
      if (this.gazou2 !== null) {
        if (typeof this.gazou2 !== "undefined") {
          formData.append("image2", this.gazou2);
        } else {
          this.gazou2 = null;
        }
      }
      if (this.gazou3 !== null) {
        if (typeof this.gazou3 !== "undefined") {
          formData.append("image3", this.gazou3);
        } else {
          this.gazou3 = null;
        }
      }
      formData.append("kanrisya_cd", localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA ? this.select_kanrisya : this.$route.query.kanrisya_cd);
      formData.append("saigai_yyyy", this.$route.query.saigai_yyyy);
      formData.append("saigai_ken", this.$route.query.saigai_ken);
      formData.append("kbn", this.$route.query.kbn);
      formData.append("seirinao", this.seirinao);

      var request_data = {
        kbn: this.$route.query.kbn,
        seirinao: this.seirinao,
        // kanrisya_cd: localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA ? this.select_kanrisya : this.$route.query.kanrisya_cd,
        kanrisya_cd: this.select_kanrisya,
        saigai_yyyy: this.$route.query.saigai_yyyy,
        saigai_ken: this.$route.query.saigai_ken,
        saigai_mei: this.$route.query.saigai_ken,
        houkoku_kbn: this.$route.query.houkoku_kbn,
        kengen_cd: localStorage.getItem("kengen_cd"),
        koushu: this.koushu,
        jigyou_cd: this.jigyou_cd,
        hisaibi: this.hisaibi != null ? moment(String(this.hisaibi)).format("YYYY-MM-DD") : null,
        shichouson: this.shichouson,
        cyouaza: this.cyouaza,
        chisaki: this.chisaki,
        rokasen_kbn: this.michikawakubun,
        rokasen_cd: this.michikawamei,
        rokasen_syubetu: this.michikawashubetsu,
        rokasen_mei: this.michikawa_mei,
        rokasen_txt: this.michikawa_txt,
        rokasen_chk: this.michikawa_chk,
        rokyouryou_mei: this.rokyouryou_mei,
        syokan_cd: this.shokan,
        kunitan: this.kunitan,
        hisaijyou_cd: this.hisaijyou,
        hisai_encyou: this.replaceComma(this.hisai_encyou),
        higai_gaku: this.replaceComma(this.higai_gaku),
        higai_gaku_itaku: this.replaceComma(this.higai_gaku_itaku),
        sayugan: this.sayugan,
        oukyukoji: this.oukyukoji,
        oukyukoji_kbn: this.oukyukoji_kbn,
        oukyukoji_start_day: this.oukyukoji_start_day ? moment(String(this.oukyukoji_start_day)).format("YYYY-MM-DD") : null,
        oukyukoji_end_day: this.oukyukoji_end_day ? moment(String(this.oukyukoji_end_day)).format("YYYY-MM-DD") : null,
        oukyukoji_houhou: this.oukyukoji_houhou,
        oukyukoji_houhou_etc: this.oukyukoji_houhou_etc,
        hisaijyou_etc: this.hisaijyou_etc,
        biko: this.biko,
        kasen_kbn: this.michikawakubun,
        kasen_syubetu: this.michikawashubetsu,
        loginId: localStorage.getItem("LOGIN_ID"),
        koushinbi: this.koushinbi,
        chk_date: this.chk_date,
        status: this.status,
        itijoho: this.itijoho,
        before_update_kanrisya_cd: this.before_update_kanrisya_cd,
      };

      if (this.oukyukoji == 2 || this.oukyukoji == 3) {
        var oukyu_error_flg = false;
        if (!this.oukyukoji_kbn) {
          this.errors.oukyukoji_kbn = "応急工事の区分を入力して下さい。";
          this.isLoading = false;
          oukyu_error_flg = true;
        }
        if (!this.oukyukoji_houhou) {
          this.errors.oukyukoji_houhou = "応急工事の方法を入力して下さい。";
          this.isLoading = false;
          oukyu_error_flg = true;
        }
        if (!this.oukyukoji_start_day) {
          this.errors.oukyukoji_start_day = "応急工事着手日を入力して下さい。";
          this.isLoading = false;
          oukyu_error_flg = true;
        }
        if (!this.oukyukoji_end_day) {
          this.errors.oukyukoji_end_day = "応急工事完了日を入力して下さい。";
          this.isLoading = false;
          oukyu_error_flg = true;
        }
        if (oukyu_error_flg) {
          return false;
        }
      }
      apiService
        .fa102Update(request_data)
        .then((result) => {
          this.info = result;
          if (!(this.gazou1 === null && this.gazou2 === null && this.gazou3 === null)) {
            apiService
              .fa102UpdateFile(formData)
              .then(() => {
                this.show();
                this.isLoading = false;
              })
              .catch((error) => {
                if (typeof error == "string") {
                  confirm(this.$messages.msg["0013E"]);
                  this.textError = error;
                }
                this.isLoading = false;
              });
          } else {
            this.show();
            this.isLoading = false;
          }
        })
        .catch((error) => {
          if (typeof error == "string") {
            confirm(this.$messages.msg["0013E"]);
            this.textError = error;
          }
          if (error.errors != null) {
            this.errors.status = error.errors.status ? error.errors.status[0] : "";
            this.errors.itijoho = error.errors.itijoho ? error.errors.itijoho[0] : "";
            this.errors.koushu = error.errors.koushu ? error.errors.koushu[0] : "";
            this.errors.hisaibi = error.errors.hisaibi ? error.errors.hisaibi[0] : "";
            this.errors.shichouson = error.errors.shichouson ? error.errors.shichouson[0] : "";
            this.errors.cyouaza = error.errors.cyouaza ? error.errors.cyouaza[0] : "";
            this.errors.hisai_encyou = error.errors.hisai_encyou ? error.errors.hisai_encyou[0] : "";
            this.errors.higai_gaku = error.errors.higai_gaku ? error.errors.higai_gaku[0] : "";
            this.errors.higai_gaku_itaku = error.errors.higai_gaku_itaku ? error.errors.higai_gaku_itaku[0] : "";
            this.errors.rokasen_kbn = error.errors.rokasen_kbn ? error.errors.rokasen_kbn[0] : "";
            this.errors.rokasen_syubetu = error.errors.rokasen_syubetu ? error.errors.rokasen_syubetu[0] : "";
            this.errors.rokasen_cd = error.errors.rokasen_cd ? error.errors.rokasen_cd[0] : "";
            this.errors.rokasen_txt = error.errors.rokasen_txt ? error.errors.rokasen_txt[0] : "";
            this.errors.rokyouryou_mei = error.errors.rokyouryou_mei ? error.errors.rokyouryou_mei[0] : "";
            this.errors.kunitan = error.errors.kunitan ? error.errors.kunitan[0] : "";
            this.errors.hisaijyou_cd = error.errors.hisaijyou_cd ? error.errors.hisaijyou_cd[0] : "";
            this.errors.oukyukoji = error.errors.oukyukoji ? error.errors.oukyukoji[0] : "";
            this.errors.hisaijyou_etc = error.errors.hisaijyou_etc ? error.errors.hisaijyou_etc[0] : "";
            this.errors.oukyukoji_houhou_etc = error.errors.oukyukoji_houhou_etc ? error.errors.oukyukoji_houhou_etc[0] : "";
            this.errors.sayugan = error.errors.sayugan ? error.errors.sayugan[0] : "";
            this.errors.gazou1 = error.errors.gazou1 ? error.errors.gazou1[0] : "";
            this.errors.gazou2 = error.errors.gazou2 ? error.errors.gazou2[0] : "";
            this.errors.gazou3 = error.errors.gazou3 ? error.errors.gazou3[0] : "";
            this.errors.select_kanrisya = error.errors.select_kanrisya ? error.errors.select_kanrisya[0] : "";
            confirm(this.$messages.msg["0013E"]);
          }
          this.isLoading = false;
        });
      scrollTo(0, 0);
    },
    delete_btn() {
      this.info = null;
      this.textError = null;
      this.clearError();
      var request_data = {
        kbn: this.$route.query.kbn,
        seirinao: this.seirinao,
        saigai_yyyy: this.$route.query.saigai_yyyy,
        saigai_ken: this.$route.query.saigai_ken,
        saigai_mei: this.$route.query.saigai_mei,
        kanrisya_cd: this.$route.query.kanrisya_cd,
        loginId: localStorage.getItem("LOGIN_ID"),
        biko: this.biko,
        chk_date: this.chk_date,
      };
      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        this.isLoading = true;
        apiService
          .fa102Delete(request_data)
          .then((result) => {
            this.info = result;
            this.clearData();
            this.kubun = 1;
            this.showFlg = false;
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            if (typeof error == "string") {
              this.textError = error;
            }
            if (error.errors != null) {
              this.errors.biko = error.errors.biko ? error.errors.biko[0] : "";
              confirm(this.$messages.msg["0013E"]);
            }
            this.isLoading = false;
          });
        scrollTo(0, 0);
      }
    },
    clearError() {
      this.errors.status = null;
      this.errors.koushu = null;
      this.errors.hisaibi = null;
      this.errors.shichouson = null;
      this.errors.cyouaza = null;
      this.errors.hisai_encyou = null;
      this.errors.higai_gaku = null;
      this.errors.higai_gaku_itaku = null;
      this.errors.rokasen_kbn = null;
      this.errors.rokasen_syubetu = null;
      this.errors.rokasen_cd = null;
      this.errors.rokasen_txt = null;
      this.errors.rokyouryou_mei = null;
      this.errors.kunitan = null;
      this.errors.hisaijyou_cd = null;
      this.errors.oukyukoji = null;
      this.errors.hisaijyou_etc = null;
      this.errors.oukyukoji_houhou_etc = null;
      this.errors.sayugan = null;
      this.errors.biko = null;
      this.errors.oukyukoji_kbn = null;
      this.errors.oukyukoji_houhou = null;
      this.errors.oukyukoji_start_day = null;
      this.errors.oukyukoji_end_day = null;
      this.errors.itijoho = null;
      this.errors.select_kanrisya = null;
    },
    clearData() {
      (this.seirinao = ""),
        (this.koushu = ""),
        (this.shichouson = ""),
        (this.cyouaza = ""),
        (this.michikawakubun = ""),
        (this.michikawashubetsu = ""),
        (this.michikawamei = ""),
        (this.kunitan = 1),
        (this.hisaijyou = ""),
        (this.rokyouryou_mei = ""),
        (this.jigyoushutaimei = "");
      this.hisaibi = "";
      this.chisaki = "";
      this.hisai_encyou = "";
      this.higai_gaku = "";
      this.higai_gaku_itaku = "";
      this.koushu_mei = "";
      this.shichouson_mei = "";
      this.cyouaza_mei = "";
      this.michikawakubun_mei = "";
      this.michikawashubetsu_mei = "";
      this.michikawa_mei = "";
      this.kuni_mei = "";
      this.hisaijyou_mei = "";
      this.higai_gaku_itaku = "";
      this.michikawa_txt = "";
      //市町村工事の場合は0
      if (this.$route.query.jigyou_cd == this.$constants.FA102.JIGYOU_SHI) {
        this.higai_gaku_itaku = 0;
      }
      //権限：被災現場確認の場合ブランク
      if (localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA) {
        this.higai_gaku_itaku = "";
      }
      this.sayugan = "";
      this.oukyukoji = "";
      this.oukyukoji_kbn = "";
      this.oukyukoji_start_day = "";
      this.oukyukoji_end_day = "";
      this.oukyukoji_houhou = "";
      this.oukyukoji_houhou_etc = "";
      this.hisaijyou_etc = "";
      this.biko = "";
      this.itijoho = null;
      this.select_kanrisya = "";
      this.jigyoushutai = "";
      this.before_update_kanrisya_cd = "";
      this.gazou1 = "";
      this.gazou2 = "";
      this.gazou3 = "";
      this.gazou1_name = "";
      this.gazou2_name = "";
      this.gazou3_name = "";
    },
    authorityAdmin() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<template>
  <AppLayout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle">
          <img src="@/assets/loader.gif" style="dipslay: inline-block; float: left" />
          <h4 style="dipslay: inline-block; float: left; color: #3399f3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main id="fa102" class="mb-3">
      <p class="col-md-12 bg-success-info text-center">{{ info }}</p>
      <p class="col-md-12 bg-err text-center err">{{ textError }}</p>
      <div class="container mb-3 mt-3">
        <div class="form-group row mb-0" style="width: 100%; max-width: 1400px">
          <div class="row ml-3 mb-3" style="width: 100%; max-width: 180px">
            <label for class="col-sm col-form-label" style="width: 100%; max-width: 110px">災害年</label>
            <div class="col-sm pr-0 pl-3" style="width: 100%; max-width: 60px">
              <input type="text" class="form-control bg-light" v-model="jpYear" readonly tabindex="1" />
            </div>
          </div>
          <div class="row ml-3 mb-3" style="width: 100%; max-width: 220px">
            <label for class="col-sm col-form-label" style="width: 100%; max-width: 110px">災害番号</label>
            <div class="col-sm pr-0 pl-3" style="width: 100%; max-width: 100px">
              <input type="text" class="form-control bg-light" v-model="saigai_ken" maxlength="9" readonly tabindex="2" />
            </div>
          </div>
          <div class="row ml-3" style="width: 100%; max-width: 400px">
            <label for class="col-sm col-form-label vertical-align-top" style="width: 100%; max-width: 110px">異常気象名</label>
            <div class="col-sm pr-0 pl-3 mb-3" style="width: 100%; max-width: 240px">
              <input type="text" class="form-control bg-light" v-model="saigai_mei" readonly tabindex="3" />
            </div>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 1400px">
          <div class="row ml-3" style="width: 100%; max-width: 400px">
            <label for class="col-sm col-form-label" style="width: 100%; max-width: 110px">管理者</label>
            <div class="row pr-0 pl-3" style="width: 100%; max-width: 300px">
              <div class="col-sm pr-0 pl-0 ml-3" style="width: 100%; max-width: 60px">
                <input type="text" class="form-control bg-light" v-model="kanrisya_cd" readonly tabindex="4" />
              </div>
              <div class="col-sm pr-0 pl-1" style="width: 100%; max-width: 200px">
                <input type="text" class="form-control bg-light" v-model="kanrisha_mei" readonly tabindex="5" />
              </div>
            </div>
          </div>
          <div for class="col-lg col-form-label ml-3 text-info" style="width: 100%; max-width: 1060px">{{ this.hou }}</div>
          <div for class="col-lg col-form-label ml-2 text-info" style="width: 100%; max-width: 1060px; font-size: 1.2em; font-weight: bold" v-show="!status_check">{{ this.admin_not_check }}</div>
        </div>
      </div>
      <form>
        <div class="container mb-3 mt-3">
          <div class="container mb-5 pt-3 clearfix border-top border-secondary">
            <div class="row">
              <div class="mr-3 mb-3 mb-md-0">
                <table class="table-sm table-bordered float-left mr-3">
                  <tr class="table-success">
                    <td>整理番号</td>
                    <td width="100px" class="text-center">{{ this.seirinao }}</td>
                  </tr>
                </table>
              </div>
              <div class="form-group row" style>
                <div class="form-check form-check-inline ml-3" style="width: 75px; float: left">
                  <label class="form-check-label" for>状態</label>
                </div>
                <div class="statusArea" id="makeImg" style="width: 250px">
                  <input type="checkbox" id="statusCheck" checked="" @change="statusCheck()" v-model="status_check" :disabled="isTestDisabled" />
                  <label for="statusCheck">
                    <div id="statusBox">
                      <span>{{ status_text_on }}</span>
                    </div>
                    <div id="statusText">
                      <span>{{ status_text_off }}</span>
                    </div>
                  </label>
                </div>
                <p style="width: 100%; max-width: 100px; white-space: nowrap" class="err" v-if="this.errors.status">{{ this.errors.status }}</p>
              </div>
            </div>
            <div class="row form-inline float-right d-flex justify-content-around">
              <button
                class="btn btn-light mr-3"
                @click="insert_btn()"
                type="button"
                style="width: 120px"
                v-show="showFlg"
                :disabled="this.houkoku_status == null || this.kubun == 2 || this.valid_flg == 0 || (this.houkoku_status == 2 && this.admin != 1) ? true : false"
              >
                登録
              </button>
              <button
                class="btn btn-light mr-3"
                @click="update_btn()"
                type="button"
                style="width: 120px"
                v-show="showFlg"
                tabindex="6"
                :disabled="this.houkoku_status == null || this.kubun == 1 || this.valid_flg == 0 || (this.houkoku_status == 2 && this.admin != 1) || (this.status == 2 && isTestDisabled == true) ? true : false"
              >
                更新
              </button>
              <button
                class="btn btn-light mr-3 mt-3 mt-sm-0"
                @click="delete_btn()"
                type="button"
                style="width: 120px"
                v-show="showFlg"
                tabindex="7"
                :disabled="this.houkoku_status == null || this.kubun == 1 || this.valid_flg == 0 || (this.houkoku_status == 2 && this.admin != 1) || (this.status == 2 && isTestDisabled == true) ? true : false"
              >
                削除
              </button>
              <router-link :to="{ name: 'FA101' }" class="btn btn-light mr-3 mt-3 mt-md-0" style="width: 120px" role="button" tabindex="8">戻る</router-link>
            </div>
          </div>
          <div class="container mb-3 mt-3">
            <div class="row">
              <div class="form-group col-lg-6 order-1" style="width: 100%; max-width: 1400px">
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">工種</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px">
                    <select id class="form-control bg-required" v-model="koushu" tabindex="9" @change="koushusChange(koushu)">
                      <option></option>
                      <option v-for="item in koushus" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.koushu }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">被災日</label>
                  <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 150px">
                    <datepicker :language="ja" v-model="hisaibi" :value="hisaibi" format="yyyy/MM/dd" :typeable="true" width="100px" tabindex="10" class="vdp-datepicker-required"></datepicker>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.hisaibi }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">管理者</label>
                  <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 350px">
                    <select id class="form-control bg-required" v-model="select_kanrisya" @change="kanrisyaChange(select_kanrisya)" tabindex="11" :disabled="kengenCheck">
                      <option></option>
                      <option v-for="kanrisya in kanrisyaList" :key="kanrisya.key" :value="kanrisya.key">{{ kanrisya.key }}：{{ kanrisya.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.select_kanrisya }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">事業主体</label>
                  <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 150px">
                    <input type="text" class="form-control" v-model="jigyoushutaimei" readonly tabindex="12" />
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red"></p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">市町村</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px">
                    <select id class="form-control bg-required" v-model="shichouson" tabindex="13" @change="shichousonsChange(shichouson)">
                      <option></option>
                      <option v-for="item in shichousons" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.shichouson }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">町字</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px">
                    <select id class="form-control" v-model="cyouaza" tabindex="14" @change="cyouazasChange(cyouaza)" :class="[isTestDisabled ? '' : 'bg-required']">
                      <option></option>
                      <option v-for="item in cyouazas" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.cyouaza }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">地先</label>
                  <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 350px">
                    <input type="text" class="form-control" v-model="chisaki" maxlength="100" tabindex="15" />
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red"></p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">路河川区分</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px">
                    <select id class="form-control bg-required" v-model="michikawakubun" tabindex="16" @change="michikawakubunsChange(michikawakubun)">
                      <option></option>
                      <option v-for="item in michikawakubuns" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.rokasen_kbn }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">路河川種別</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px">
                    <select id class="form-control bg-required" v-model="michikawashubetsu" tabindex="17" @change="michikawashubetsuChange(michikawashubetsu)">
                      <option></option>
                      <option v-for="item in michikawashubetsus" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.rokasen_syubetu }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 150px">路河川名</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 330px">
                    <input type="radio" style="vertical-align: middle" v-model="michikawa_chk" tabindex="18" :disabled="this.jigyou_cd == this.$constants.FA102.JIGYOU_SHI ? true : false" v-bind:value="1" />
                    <select
                      id
                      class="form-control"
                      v-model="michikawamei"
                      :class="michikawa_required && michikawa_chk == '1' ? 'bg-required' : ''"
                      @change="michikawameiChange(michikawamei)"
                      tabindex="19"
                      :disabled="this.jigyou_cd == this.$constants.FA102.JIGYOU_SHI ? true : false"
                      style="width: 90%; float: right"
                    >
                      <option></option>
                      <option v-for="item in michikawameis" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 90%; white-space: nowrap; color: red; float: right">{{ this.errors.rokasen_cd }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 150px">路河川名<br />(直接入力)</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 330px">
                    <input type="radio" style="vertical-align: middle" v-model="michikawa_chk" tabindex="20" v-bind:value="2" />
                    <input type="text" class="form-control" style="width: 90%; float: right" :class="michikawa_chk == '2' ? 'bg-required' : ''" v-model="michikawa_txt" maxlength="100" tabindex="20" />
                    <p style="width: 90%; white-space: nowrap; color: red; float: right">{{ this.errors.rokasen_txt }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">橋梁名</label>
                  <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 350px">
                    <input type="text" class="form-control" :class="kyouryou_required ? 'bg-required' : ''" v-model="rokyouryou_mei" maxlength="100" tabindex="21" />
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.rokyouryou_mei }}</p>
                  </div>
                </div>
                <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 30px"></div>
              </div>

              <div class="form-group col-lg-6 order-4 order-lg-2" style="width: 100%; max-width: 1400px">
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 240px">応急工事</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px">
                    <select id class="form-control bg-required" v-model="oukyukoji" tabindex="30" @change="oukyukojiChange(oukyukoji)" :disabled="isTestDisabled">
                      <option></option>
                      <option v-for="item in oukyukojis" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.oukyukoji }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 240px">応急工事の区分</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px">
                    <select id class="form-control" v-model="oukyukoji_kbn" tabindex="31" :class="[this.bg_required ? 'bg-required' : '']" :disabled="isTestDisabled">
                      <option></option>
                      <option v-for="item in oukyukoji_kbns" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.oukyukoji_kbn }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 240px">応急工事の方法</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px" tabindex="32">
                    <select id class="form-control" :class="[this.bg_required ? 'bg-required' : '']" v-model="oukyukoji_houhou" @change="oukyukojiHouhousChange(oukyukoji_houhou)" :disabled="isTestDisabled">
                      <option></option>
                      <option v-for="item in oukyukoji_houhous" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.oukyukoji_houhou }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label text-lg-right" style="width: 100%; max-width: 240px">その他時必須</label>
                  <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 230px">
                    <input type="text" class="form-control" v-model="oukyukoji_houhou_etc" v-bind:class="{ 'bg-required': oukyukoji_houhou_sonota_selected }" tabindex="33" maxlength="100" :readonly="isTestDisabled" />
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.oukyukoji_houhou_etc }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 240px">応急工事着手日</label>
                  <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 150px">
                    <datepicker
                      :language="ja"
                      v-model="oukyukoji_start_day"
                      :value="oukyukoji_start_day"
                      format="yyyy/MM/dd"
                      :typeable="true"
                      width="100px"
                      tabindex="34"
                      :class="[this.bg_required ? 'vdp-datepicker-required' : '']"
                      :disabled="isTestDisabled"
                    ></datepicker>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.oukyukoji_start_day }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 240px">応急工事完了日（見込み）</label>
                  <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 150px">
                    <datepicker
                      :language="ja"
                      v-model="oukyukoji_end_day"
                      :value="oukyukoji_end_day"
                      format="yyyy/MM/dd"
                      :typeable="true"
                      width="100px"
                      tabindex="35"
                      :class="[this.bg_required ? 'vdp-datepicker-required' : '']"
                      :disabled="isTestDisabled"
                    ></datepicker>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.oukyukoji_end_day }}</p>
                  </div>
                </div>
              </div>
              <div class="form-group col-lg-6 order-2 order-lg-3" style="width: 100%; max-width: 1400px">
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">予算区分</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px">
                    <select id class="form-control" v-model="kunitan" tabindex="22" @change="kunitanChange(kunitan)" :disabled="isTestDisabled" :class="[isTestDisabled ? '' : 'bg-required']">
                      <option></option>
                      <option v-for="item in kunitans" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.kunitan }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">被災状況</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px">
                    <select id class="form-control bg-required" v-model="hisaijyou" tabindex="23" @change="hisaijyouChange(hisaijyou)">
                      <option></option>
                      <option v-for="item in hisaijyous" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.hisaijyou_cd }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label text-lg-right" style="width: 100%; max-width: 250px">その他時必須</label>
                  <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 230px">
                    <input type="text" class="form-control" v-model="hisaijyou_etc" v-bind:class="{ 'bg-required': hisaijyou_sonota_selected }" maxlength="100" tabindex="24" />
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.hisaijyou_etc }}</p>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">左右岸</label>
                  <div class="col-sm pr-0 pl-0" value style="width: 100%; max-width: 350px">
                    <select id class="form-control" :class="sayugan_required ? 'bg-required' : ''" v-model="sayugan" tabindex="25">
                      <option></option>
                      <option v-for="item in sayugans" :key="item.key" :value="item.key">{{ item.key }}：{{ item.value }}</option>
                    </select>
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.sayugan }}</p>
                  </div>
                  <label for class="col-sm col-form-label text-left pr-0 pl-1" style="width: 100%; max-width: 150px">※河川のみ</label>
                </div>
              </div>

              <div class="form-group col-lg-6 order-5 order-lg-4" style="width: 100%; max-width: 1400px">
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 240px">被災延長</label>
                  <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 200px">
                    <input type="tel" class="form-control text-right" v-model="hisai_encyou" :maxlength="max" tabindex="36" @blur="hisaiChange(hisai_encyou)" @focus="hisaiFocus()" :class="[isTestDisabled ? '' : 'bg-required']" />
                    <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.hisai_encyou }}</p>
                  </div>
                  <label for class="col-sm col-auto col-form-label text-left pr-0 pl-1 align-item-end" style="width: 100%; max-width: 100px">m(箇所)</label>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 240px">被災報告額</label>
                  <div class="row col-sm pr-0 pl-3">
                    <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 200px">
                      <input type="tel" class="form-control bg-required text-right" v-model="higai_gaku" maxlength="9" tabindex="37" @blur="higaiChange(higai_gaku)" @focus="higaiFocus()" :readonly="isTestDisabled" />
                      <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.higai_gaku }}</p>
                    </div>
                    <label for class="col-sm col-form-label text-left pr-0 pl-1" style="width: 100%; max-width: 120px">千円（工事費）</label>
                  </div>
                </div>
                <div class="row">
                  <label for class="col-sm col-form-label" style="width: 100%; max-width: 240px"></label>
                  <div class="row col-sm pr-0 pl-3">
                    <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 200px">
                      <input
                        type="tel"
                        class="form-control bg-required text-right"
                        v-model="higai_gaku_itaku"
                        maxlength="9"
                        tabindex="38"
                        :disabled="this.jigyou_cd == this.$constants.FA102.JIGYOU_SHI ? true : false"
                        @blur="higaiItakuChange(higai_gaku_itaku)"
                        @focus="higaiItakuFocus()"
                        :readonly="isTestDisabled"
                      />
                      <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.higai_gaku_itaku }}</p>
                    </div>
                    <label for class="col-sm col-form-label text-left pr-0 pl-1" style="width: 100%; max-width: 120px">千円（委託費）</label>
                  </div>
                </div>
              </div>
              <div class="form-group order-3 order-lg-5" style="width: 100%; max-width: 1400px">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="row pl-3">
                      <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">画像ファイル</label>
                      <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 350px">
                        <input class="form-control mb-1" @change="fileSelected1" type="file" name="first_image" tabindex="26" />
                        <p style="width: 100%; max-width: 300px; white-space: nowrap; margin-bottom: 3px">{{ this.gazou1_name }}</p>
                        <input type="file" class="form-control mb-1" @change="fileSelected2" tabindex="27" />
                        <p style="width: 100%; max-width: 300px; white-space: nowrap; margin-bottom: 0px">{{ this.gazou2_name }}</p>
                        <input type="file" class="form-control mb-1" @change="fileSelected3" tabindex="28" />
                        <p style="width: 100%; max-width: 300px; white-space: nowrap; margin-bottom: 0px">{{ this.gazou3_name }}</p>
                      </div>
                      <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red"></p>
                    </div>
                    <div class="row pl-3">
                      <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">位置情報</label>
                      <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 350px">
                        <input type="text" class="form-control mb-1" v-model="itijoho" maxlength="100" @change="showMap" tabindex="29" />
                        <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.itijoho }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 d-flex justify-content-end justify-content-sm-center">
                    <div ref="map" id="mapArea" style="width: 600px; height: 450px"></div>
                  </div>
                </div>
              </div>
            </div>
            <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">備考</label>
            <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 800px">
              <textarea name="remarks" id="remarks" rows="4" placeholder="削除時必須（削除理由）" class="form-control" v-model="biko" style="background-color: #edfffa" maxlength="1000" tabindex="35"></textarea>
              <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">{{ this.errors.biko }}</p>
            </div>
            <div class="row form-inline float-right d-flex justify-content-around">
              <button
                class="btn btn-light mr-3"
                @click="insert_btn()"
                type="button"
                style="width: 120px"
                v-show="showFlg"
                tabindex="36"
                :disabled="this.houkoku_status == null || this.kubun == 2 || this.valid_flg == 0 || (this.houkoku_status == 2 && this.admin != 1) ? true : false"
              >
                登録
              </button>
              <button
                class="btn btn-light mr-3"
                @click="update_btn()"
                type="button"
                style="width: 120px"
                v-show="showFlg"
                tabindex="37"
                :disabled="this.houkoku_status == null || this.kubun == 1 || this.valid_flg == 0 || (this.houkoku_status == 2 && this.admin != 1) || (this.status == 2 && isTestDisabled == true) ? true : false"
              >
                更新
              </button>
              <button
                class="btn btn-light mr-3 mt-3 mt-sm-0"
                @click="delete_btn()"
                type="button"
                style="width: 120px"
                v-show="showFlg"
                tabindex="38"
                :disabled="this.houkoku_status == null || this.kubun == 1 || this.valid_flg == 0 || (this.houkoku_status == 2 && this.admin != 1) || (this.status == 2 && isTestDisabled == true) ? true : false"
              >
                削除
              </button>
              <router-link :to="{ name: 'FA101' }" class="btn btn-light mr-3 mt-3 mt-md-0" style="width: 120px" role="button" tabindex="39">戻る</router-link>
            </div>
          </div>
        </div>
      </form>
    </main>
  </AppLayout>
</template>
